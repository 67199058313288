import React, { useState } from "react";
import styled from "@emotion/styled";

import { setTransStore } from "../../utils/store";
import { ChatSDK } from "../../utils/chat-sdk";
const MessageWrapper = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  // flex-direction: column;
  justify-content: ${({ isCustomer }) =>
    isCustomer ? "flex-start" : "flex-end"};
    font-size:14px;
    padding: ${({ isCustomer }) =>
    isCustomer ? "30px 150px 0 26px" : "30px 5px 0 150px"};
`;

const MessageAuthor = styled.small`
  position: absolute;
  top: 6px;
  z-index:10;
  > span{
    display:${({ isCustomer }) =>
    isCustomer ? "none !important" : "block"};
  }
`;

const getBackground = (isCustomer, isFromBot, theme) => {
  if (isFromBot) {
    return '#88caff';
  }
  return isCustomer ? '#fff' : '#88caff'
}


const MessageBox = styled.div`

`;

const MessageCon = styled.div`
  padding: 13px 10px 5px 20px;
  background: ${({ isCustomer, isFromBot, theme }) => getBackground(isCustomer, isFromBot, theme)};
  color:#000;
  border-radius: 16px;
  margin-right:12px;
  position:relative;
`;

const MessageText = styled.p`
  color: ${({ isCustomer }) => (isCustomer ? "black" : "black")};
  border-radius: 10px;
  padding:0 0 10px 0;
  font-weight:500;
  position: relative;
  z-index:2;
  white-space: pre-wrap;
  word-break: break-all;
`;
const MessageTime = styled.p`
  color: ${({ isCustomer }) => (isCustomer ? "#9facb5" : "#297bb5")};
  font-size:12px;
  text-align:right;
  position: relative;
  z-index:2;
`;
const MessageFany = styled.div`
  padding:0 24px 0 16px;
  p{ background:#bbbcc0; color:#fff; padding:7px 12px 9px; line-height:24px;     border-radius: 12px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;}
`;
const getBackgroundImg = (isCustomer, isFromBot, theme) => {
  if (isFromBot) {
    return 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB8AAAAYCAMAAAA1ddazAAAAKlBMVEVMaXGIyv+Iyv+Iyv+Iyv+Iyv+Iyv+Iyv+Iyv+Iyv+Iyv+Iyv+Iyv+Iyv/tn72SAAAADXRSTlMA9w3uU5wyY+WTPgF4m38cpAAAAERJREFUeNrlzTESwCAMA0FsAwkJ+v93KajxPYBrdzQqOlR2t7qBB+0d/jv4A97A3z93dfABHp67Jnh47mrgquD25S6rC8seHq9aKYpAAAAAAElFTkSuQmCC'
  }
  return isCustomer ? 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB8AAAAYCAMAAAA1ddazAAAALVBMVEVMaXH///////////////////////////////////////////////////////9xAJ22AAAADnRSTlMAme70CVL6Mg94PQFj5fWPoKoAAABDSURBVHja5cuxFYAwDMRQ7CTYCXD7j5uGFnkA1P6n400f/cXPwp09iz/Efhdu7NfDHmI39u7sU+jp7Cb0IfTV0AeyNpgKIRfPwLVXAAAAAElFTkSuQmCC'
    : 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB8AAAAYCAMAAAA1ddazAAAAKlBMVEVMaXGIyv+Iyv+Iyv+Iyv+Iyv+Iyv+Iyv+Iyv+Iyv+Iyv+Iyv+Iyv+Iyv/tn72SAAAADXRSTlMA9w3uU5wyY+WTPgF4m38cpAAAAERJREFUeNrlzTESwCAMA0FsAwkJ+v93KajxPYBrdzQqOlR2t7qBB+0d/jv4A97A3z93dfABHp67Jnh47mrgquD25S6rC8seHq9aKYpAAAAAAElFTkSuQmCC'
}

const MessageBgWei = styled.div`
position: absolute;
    width: 31px;
    height: 24px;
    bottom: 0;
    z-index:1;
    left: ${({ isCustomer }) => (isCustomer ? '-7px' : "inherit")};
    right: ${({ isCustomer }) => (isCustomer ? 'inherit' : "-7px")};
    background:url( ${({ isCustomer, isFromBot, theme }) => getBackgroundImg(isCustomer, isFromBot, theme)}) no-repeat 0 0 ;
`;


const MessageChineseText = styled.p`

`;
const ResendBot = styled.p`
   background:#04aa33 !important
`;

const showTips = (isCustomer, messagetext) => {
  if (isCustomer) {
    return messagetext.toLowerCase().indexOf("gọi admin") >= 0 || messagetext.toLowerCase().indexOf("transferir para atendimento humano") >= 0 ? "block" : "none"
  } else {
    return 'none'
  }
}
const RenGongBox = styled.div`
  display:${({ isCustomer, messagetext }) => showTips(isCustomer, messagetext)}
`;
const RenGongSpir = styled.div`
  display:${({ isCustomer, messagetext }) => showTips(isCustomer, messagetext)}
`;




const Message = ({ message, user, chatid }) => {
  const [chineseText, setChineseText] = useState("");
  const [chineseText2, setChineseText2] = useState("");

  const userName = user?.name || "";
  const isCustomer = user?.type === "customer";
  const isFromBot = user?.type != "customer" && message.text.endsWith("(bot)")

  const botjsob = [
    { cid: "52b8051e4af26aa799d8c3a1ab31f1c3", bot: "/vn_66_faqbot", msbot: "/vn_66_msbot", url: "https://www.b66bak.club" },
    { cid: "814552ce53c68742c3989f3b61bd760d", bot: "/vn_66_faqbot", msbot: "/vn_66_msbot", url: "https://www.b66bak.club" },
    { cid: "7dd9b143440e4a4f0276e2ea8533d356", bot: "/vn_369_faqbot", msbot: "/vn_369_msbot", url: "https://www.b369bak.com" },
    { cid: "454eab6624be0df1158b4eb3122c4dca", bot: "/vn_g666_faqbot", msbot: "/vn_g666_msbot", url: "https://www.g666.win" },
    { cid: "8096fbe76bb37fa89550d76bdee483cf", bot: "/vn_777_faqbot", msbot: "/vn_777_msbot", url: "https://www.66sun.win" }
  ]
  const translate = (message) => (e) => {
    if (!message.text || message.chineseText || chineseText) {
      return
    }

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'x-api-key': '1f2d3e345a6596de7d6b855c3d7f481e9e852c0243d3d3a8e4cc4a4a4b4a4b4c' },
      body: JSON.stringify({ text: message.text })

    };
    fetch('/Translate_to_Chinese', requestOptions)
      .then(res => res.json())
      .then(data => {

        //message.chineseText=data.translated_text
        setChineseText(data.translated_text)

        setTransStore(chatid, message.id, data.translated_text)
      })

  }


  const gpttranslate = (message) => (e) => {
    if (!message.text || chineseText2) {
      return
    }

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'x-api-key': '1f2d3e345a6596de7d6b855c3d7f481e9e852c0243d3d3a8e4cc4a4a4b4a4b4c' },
      body: JSON.stringify({ text: message.text })

    };
    fetch('/GPT4_Translate_to_Chinese', requestOptions)
      .then(res => res.json())
      .then(data => {

        //message.chineseText=data.translated_text
        setChineseText2(data.translated_text)
        setTransStore(chatid, message.id, data.translated_text)
      })

  }



  const reBot = () => {


    const boturl = getBotName(true)

    fetchbot(boturl, 0)
  }

  const getBotName = (isfb) => {
    const botitem = botjsob.find(item => item.cid === process.env.REACT_APP_CLIENT_ID);
    if (botitem) {
      return isfb ? botitem.msbot : botitem.bot;
    }
    return "/";
  }
  const fetchbot = (boturl, errorNum) => {
    if (errorNum >= 3) {

      console.error("请求bot，失败三次");
      return;
    }
    const requestOptions2 = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'x-api-key': '1f2d3e345a6596de7d6b855c3d7f481e9e852c0243d3d3a8e4cc4a4a4b4a4b4c' },
      body: JSON.stringify({ message: message.text, chatid: chatid })
    };
    fetch(boturl, requestOptions2)
      .then(res => res.json())
      .then(data => {
        try {

          let reply = data?.reply;
          botSend(reply, chatid)
        } catch (error) {
          // needAdmin(chat_id)
          console.error("bot 请求成功 内部错误", error)
        }

      }).catch(error => {
        console.error("bot 请求发送错误", error)
        fetchbot(boturl, errorNum + 1)
      })
  }


  const botSend = (reply) => {
    if (reply?.[0] === '"') {
      reply = reply.substr(1);
    }
    if (reply?.length && reply?.[reply?.length - 1] === '"') {
      reply = reply.substr(0, reply.length - 1);
    }
    // sendMessage(payload.chat_id, reply + "(bot)");
    ChatSDK.sendMessage(chatid, reply + "(bot)").then(data => {
      if (message.text.toLowerCase().includes("cdkey")) {
        setTimeout(function () {

          close_chat(chatid)

        }, 3000);
      }
    }).catch(error => {
      console.error("sendMessage01 发送错误", error)
      setTimeout(() => {
        ChatSDK.sendMessage(chatid, reply + "(bot)").then(data2 => {
          if (message.text.toLowerCase().includes("cdkey")) {
            setTimeout(function () {
              close_chat()
            }, 3000);
          }
        }).catch(error2 => {

          // needAdmin(chat_id)
          console.error("sendMessage02 发送错误", error2)

        })
      }, 1000);
    })
  }

  const close_chat = () => {
    try {

      fetch('https://api.livechatinc.com/v3.5/agent/action/deactivate_chat', {
        method: 'POST',
        body: JSON.stringify({
          "id": chatid
        }),
        headers: {
          "Content-Type": "application/json",
          "Authorization": ChatSDK._getToken()
        }
      }).then(res => res.json())
        .then(data => {

        })
        .catch(error => console.log(error));
      return true;
    } catch (error) {
      console.log("close chat error---", error)
    }
  }
  return (
    <>
      <MessageWrapper isCustomer={isCustomer}>
        <MessageAuthor isCustomer={isCustomer} isFromBot={isFromBot}>
          {/* {userName} */}
          {/* {message.isFromBot ? '11':'false'} */}
          <span class="icon-msg-robot" style={{ 'display': isFromBot ? 'block' : 'none' }}></span>
          <span class="icon-msg-rengong" style={{ 'display': !isFromBot ? 'block' : 'none' }}></span>
        </MessageAuthor>
        <MessageBox>
          <MessageCon isCustomer={isCustomer} isFromBot={isFromBot}>
            <MessageText isCustomer={isCustomer} isFromBot={isFromBot}>{message.text}</MessageText>
            <MessageTime isCustomer={isCustomer} isFromBot={isFromBot}>
              {new Date(message?.created_at).toLocaleString()}
              {/* <span class="icon-unread"></span>
              <span class="icon-read"></span> */}
            </MessageTime>
            <MessageBgWei isCustomer={isCustomer} isFromBot={isFromBot}></MessageBgWei>
          </MessageCon>
          <MessageFany isCustomer={isCustomer}>
            {(chineseText2 != null && chineseText2 !== "") && < MessageChineseText isCustomer={isCustomer}>{chineseText2}</MessageChineseText>}
            {(chineseText2 === "" && ((message.chineseText && message.chineseText !== '') || (chineseText && chineseText !== ''))) &&
              <MessageChineseText isCustomer={isCustomer}>{chineseText2 || message.chineseText || chineseText} </MessageChineseText>

            }
            {(chineseText2 === "" && ((message.chineseText && message.chineseText !== '') || (chineseText && chineseText !== ''))) &&
              <span onClick={gpttranslate(message)} style={{ cursor: "pointer" }} > Advance Translator</span>
            }
            {(message.chineseText == null || message.chineseText === '') && (chineseText == null || chineseText === '') && <MessageChineseText onClick={translate(message)} isCustomer={isCustomer}>Translator</MessageChineseText>}

            {isCustomer && <ResendBot onClick={() => reBot()} isCustomer={isCustomer}>Resend bot</ResendBot>}
          </MessageFany>

          <RenGongSpir className="rengong-spir" isCustomer={isCustomer} messagetext={message.text}></RenGongSpir>
          <RenGongBox className="rengong-tips" isCustomer={isCustomer} messagetext={message.text}>
            <div className="rengong-tips-li">
              <span className="line"></span>
              <span className="icon-jinggao"></span>
              <p>Customer has requested human service</p>
              <span className="icon-jinggao"></span>
              <span className="line"></span>
            </div>
            <div className="rengong-tips-li">
              <span className="line"></span>
              <span className="icon-jinggao"></span>
              <p>Manual service accepted</p>
              <span className="icon-jinggao"></span>
              <span className="line"></span>
            </div>
          </RenGongBox>

        </MessageBox>

      </MessageWrapper>
    </>
  );
};

export default Message;
